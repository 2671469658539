import { EvaluationSetUUID } from './evaluationSetsTypes';
import { SearchFilterUUID } from '../searchFilters/searchFiltersTypes';

export class EvaluationSetCache {
  static indexCacheKey() {
    return 'evaluation-sets';
  }

  static dashboardCacheKey() {
    return 'evaluation-sets-dashboard';
  }

  static showCacheKey() {
    return 'evaluation-set';
  }

  static showQueryKey(evaluationSetUuid: EvaluationSetUUID) {
    return ['evaluation-set', evaluationSetUuid];
  }

  static filteredCacheKey(searchFilterUuid: SearchFilterUUID) {
    return `evaluation-sets-filtered-${searchFilterUuid}`;
  }
}
