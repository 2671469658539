import {
  BooleanFilter,
  CreatedAt,
  DateFilterType,
  ID,
  StringFilter,
  TextFilterType,
  UpdatedAt,
  UUID,
  UUIDFilter
} from '../../../types';

import {
  EvaluationAccuracy,
  EvaluationCommunication,
  EvaluationTiming,
  EvaluationQuality,
  EvaluationTaskError,
  EvaluationProjectError,
  EvaluationProfileError,
  EvaluationBriefError,
  EvaluationEstimateError,
  EvaluationStyleError,
  EvaluationBurningTasks,
  EvaluationRespondRate,
  EvaluationEstimateRate,
  EvaluationQualityAssurance,
  EvaluationTextureLightingCamera,
  EvaluationCompositionDetailsStorytelling,
  EvaluationUserClient,
  EvaluationUserFullName,
  EvaluationUserImageFile,
  EvaluationUserImageUUID,
  EvaluationUserNanoID,
  EvaluationUserRoleName,
  EvaluationUUID,
  EvaluationUserFinanceRoleName
} from '../../evaluations/evaluationsTypes';

import {
  ProjectCreatedAt,
  ProjectDescription,
  ProjectEstimateCost,
  ProjectID,
  ProjectMembersClient,
  ProjectMembersFinanceRoleName,
  ProjectMembersFullName,
  ProjectMembersImageFile,
  ProjectMembersImageUUID,
  ProjectMembersNanoID,
  ProjectMembersRoleName,
  ProjectMembersUUID,
  ProjectName,
  ProjectNanoID,
  ProjectUserClient,
  ProjectUserFinanceRoleName,
  ProjectUserFullName,
  ProjectUserImageFile,
  ProjectUserImageUUID,
  ProjectUserNanoID,
  ProjectUserRoleName,
  ProjectUserUUID,
  ProjectUUID
} from '../../projects/projectsTypes';

export type EvaluationSetAccuracy = number;
export type EvaluationSetBriefError = number;
export type EvaluationSetBurningTasks = number;
export type EvaluationSetCommunication = number;
export type EvaluationSetCompositionDetailsStorytelling = number;
export type EvaluationSetCreatedAt = CreatedAt;
export type EvaluationSetEstimateError = number;
export type EvaluationSetEstimateRate = number;
export type EvaluationSetID = ID;
export type EvaluationSetProfileError = number;
export type EvaluationSetProjectError = number;
export type EvaluationSetQuality = number;
export type EvaluationSetQualityAssurance = number;
export type EvaluationSetRespondRate = number;
export type EvaluationSetState = string;
export type EvaluationSetStyleError = number;
export type EvaluationSetTaskError = number;
export type EvaluationSetTextureLightingCamera = number;
export type EvaluationSetTiming = number;
export type EvaluationSetUUID = UUID;
export type EvaluationSetUpdatedAt = UpdatedAt;
export type EvaluationSetTotalRate = number;

export type EvaluationSetProjectID = ProjectID;
export type EvaluationSetProjectUUID = ProjectUUID;
export type EvaluationSetProjectNanoID = ProjectNanoID;
export type EvaluationSetProjectName = ProjectName;
export type EvaluationSetProjectDescription = ProjectDescription;
export type EvaluationSetProjectCreatedAt = ProjectCreatedAt;
export type EvaluationSetProjectEstimateCost = ProjectEstimateCost;
export type EvaluationSetProjectUserUUID = ProjectUserUUID;
export type EvaluationSetProjectUserNanoID = ProjectUserNanoID;
export type EvaluationSetProjectUserClient = ProjectUserClient;
export type EvaluationSetProjectUserFullName = ProjectUserFullName;
export type EvaluationSetProjectUserImageUUID = ProjectUserImageUUID;
export type EvaluationSetProjectUserImageFile = ProjectUserImageFile;
export type EvaluationSetProjectUserRoleName = ProjectUserRoleName;
export type EvaluationSetProjectUserFinanceRoleName =
  ProjectUserFinanceRoleName;

export type EvaluationSetProjectMembersUUID = ProjectMembersUUID;
export type EvaluationSetProjectMembersNanoID = ProjectMembersNanoID;
export type EvaluationSetProjectMembersFullName = ProjectMembersFullName;
export type EvaluationSetProjectMembersImageUUID = ProjectMembersImageUUID;
export type EvaluationSetProjectMembersImageFile = ProjectMembersImageFile;
export type EvaluationSetProjectMembersClient = ProjectMembersClient;
export type EvaluationSetProjectMembersRoleName = ProjectMembersRoleName;
export type EvaluationSetProjectMembersFinanceRoleName =
  ProjectMembersFinanceRoleName;

export type EvaluationSetEvaluationsUUID = EvaluationUUID;
export type EvaluationSetEvaluationsAccuracy = EvaluationAccuracy;
export type EvaluationSetEvaluationCommunication = EvaluationCommunication;
export type EvaluationSetEvaluationTiming = EvaluationTiming;
export type EvaluationSetEvaluationQuality = EvaluationQuality;
export type EvaluationSetEvaluationTaskError = EvaluationTaskError;
export type EvaluationSetEvaluationProjectError = EvaluationProjectError;
export type EvaluationSetEvaluationProfileError = EvaluationProfileError;
export type EvaluationSetEvaluationBriefError = EvaluationBriefError;
export type EvaluationSetEvaluationEstimateError = EvaluationEstimateError;
export type EvaluationSetEvaluationStyleError = EvaluationStyleError;
export type EvaluationSetEvaluationBurningTasks = EvaluationBurningTasks;
export type EvaluationSetEvaluationRespondRate = EvaluationRespondRate;
export type EvaluationSetEvaluationEstimateRate = EvaluationEstimateRate;
export type EvaluationSetEvaluationQualityAssurance =
  EvaluationQualityAssurance;
export type EvaluationSetEvaluationTextureLightingCamera =
  EvaluationTextureLightingCamera;
export type EvaluationSetEvaluationCompositionDetailsStorytelling =
  EvaluationCompositionDetailsStorytelling;

export type EvaluationSetEvaluationsUserNanoID = EvaluationUserNanoID;
export type EvaluationSetEvaluationsUserFullName = EvaluationUserFullName;
export type EvaluationSetEvaluationsUserClient = EvaluationUserClient;
export type EvaluationSetEvaluationsUserRoleName = EvaluationUserRoleName;
export type EvaluationSetEvaluationsUserFinanceRoleName =
  EvaluationUserFinanceRoleName;
export type EvaluationSetEvaluationsUserImageUUID = EvaluationUserImageUUID;
export type EvaluationSetEvaluationsUserImageFile = EvaluationUserImageFile;

export type EvaluationSetStateFilter = TextFilterType;
export type EvaluationSetProjectUuidFilter = UUIDFilter;
export type EvaluationSetProjectNameFilter = StringFilter;
export type EvaluationSetNameFilter = TextFilterType;
export type EvaluationSetCreatedAtFilter = DateFilterType;
export type EvaluationSetOwnFilter = BooleanFilter;

export const enum EvaluateEvaluationSetFields {
  ACCURACY = 'accuracy',
  COMMUNICATION = 'communication',
  QUALITY = 'quality',
  TIMING = 'timing',
  TOTAL_RATE = 'totalRate'
}
