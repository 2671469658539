import { Control } from 'react-hook-form';

import { IsLoading } from '../../../../../types';
import { EvaluateEvaluationSetClientFormData } from './EvaluateEvaluationSetClientForm.types';
import { EvaluateEvaluationSetFields } from '../../../evaluationSetsTypes';

import { Form } from '../../../../../helpers/Form';
import { Translate } from '../../../../../helpers/Translate';
import { StarsRadioButtonsField } from '../../../../../helpers/FormFields/StarsRadioButtonsField';

import { evaluationsKeys, tasksKeys } from '../../../../../locales/keys';

interface EvaluateEvaluationSetClientFormProps {
  control: Control<EvaluateEvaluationSetClientFormData>;
  form: string;
  isDetailedView?: boolean;
  isLoading: IsLoading;
}

function EvaluateEvaluationSetClientForm({
  control,
  form,
  isDetailedView,
  isLoading
}: EvaluateEvaluationSetClientFormProps) {
  return (
    <Form id={form}>
      <p className="text-sm font-medium">
        <Translate id={tasksKeys.pleaseRate} />
      </p>
      <div>
        <StarsRadioButtonsField
          amount={6}
          name={EvaluateEvaluationSetFields.TOTAL_RATE}
          control={control}
          disabled={isLoading}
          iconClassName="h-7 w-7"
        />
      </div>

      {isDetailedView ? (
        <div className="mt-4 space-y-2">
          <p className="text-sm font-medium">
            <Translate id={evaluationsKeys.pleaseHelpUsToBecomeBetter} />
          </p>

          <div className="grid gap-2 grid grid-cols-2 sm:grid-cols-4">
            <div>
              <p className="text-sm font-bold">
                <Translate id={evaluationsKeys.fields.accuracy} />
              </p>
              <div>
                <StarsRadioButtonsField
                  amount={6}
                  name={EvaluateEvaluationSetFields.ACCURACY}
                  control={control}
                  disabled={isLoading}
                  iconClassName="h-4 w-4"
                />
              </div>
            </div>
            <div>
              <p className="text-sm font-bold">
                <Translate id={evaluationsKeys.fields.timing} />
              </p>
              <div>
                <StarsRadioButtonsField
                  amount={6}
                  name={EvaluateEvaluationSetFields.TIMING}
                  control={control}
                  disabled={isLoading}
                  iconClassName="h-4 w-4"
                />
              </div>
            </div>
            <div>
              <p className="text-sm font-bold">
                <Translate id={evaluationsKeys.fields.quality} />
              </p>
              <div>
                <StarsRadioButtonsField
                  amount={6}
                  name={EvaluateEvaluationSetFields.QUALITY}
                  control={control}
                  disabled={isLoading}
                  iconClassName="h-4 w-4"
                />
              </div>
            </div>
            <div>
              <p className="text-sm font-bold">
                <Translate id={evaluationsKeys.fields.communication} />
              </p>
              <div>
                <StarsRadioButtonsField
                  amount={6}
                  name={EvaluateEvaluationSetFields.COMMUNICATION}
                  control={control}
                  disabled={isLoading}
                  iconClassName="h-4 w-4"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Form>
  );
}

export default EvaluateEvaluationSetClientForm;
