import { useCallback, useState } from 'react';
import isArray from 'lodash/isArray';
import size from 'lodash/size';
import omit from 'lodash/omit';

import {
  EvaluateEvaluationSetFields,
  EvaluationSetUUID
} from '../../../../../evaluationSetsTypes';
import {
  EvaluateEvaluationSetClientFormData,
  EvaluateEvaluationSets
} from '../../EvaluateEvaluationSetClientForm.types';

import {
  EVALUATE_EVALUATION_SET_QUERY,
  EvaluateEvaluationSetQueryResponse
} from '../../../../../queries/evaluateEvaluationSet.query';

import { useEvaluateEvaluationSet } from '../../../../../hooks/useEvaluateEvaluationSet';
import { useModal } from '../../../../../../../helpers/modals/hooks/useModal';
import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import { LocalForage } from '../../../../../../../utils/LocalForage';

import { evaluateEvaluationSetClientFormSchema } from './evaluateEvaluationSetClientForm.schema';

import { EvaluationSetCache } from '../../../../../EvaluationSetCache';

import {
  defaultClientEvaluationValues,
  defaultWorkerEvaluationValues,
  DEFER_EVALUATION_TIME,
  MAX_EVALUATION_RATE,
  MAX_EVALUATION_RATES
} from '../../evaluateEvaluationSetClientFormConstants';
import { POSTPONED_EVALUATION_SET_UUIDS } from '../../../../../evaluationSetsConstants';

interface EvaluateEvaluationSetClientFormOptions {
  evaluationSets: EvaluateEvaluationSets;
}

function useEvaluateEvaluationSetClientForm({
  evaluationSets
}: EvaluateEvaluationSetClientFormOptions) {
  const { isOpen, hideModal } = useModal({ isModalOpen: true });

  const evaluationSetsSize = size(evaluationSets);

  const [evaluationSetIndex, setEvaluationSetIndex] = useState<number>(0);

  const handleNextEvaluationSetIndex = useCallback<() => void>(
    () => setEvaluationSetIndex((prevState) => prevState + 1),
    []
  );

  const { control, handleSubmitReactHookForm, resetForm, watch } =
    useReactHookForm<EvaluateEvaluationSetClientFormData>({
      defaultValues: defaultClientEvaluationValues,
      schema: evaluateEvaluationSetClientFormSchema
    });

  const handleHideModal = useCallback<() => void>(() => {
    resetForm();

    if (evaluationSetIndex < evaluationSetsSize - 1) {
      return handleNextEvaluationSetIndex();
    }

    hideModal();
  }, [
    evaluationSetIndex,
    evaluationSetsSize,
    handleNextEvaluationSetIndex,
    hideModal,
    resetForm
  ]);

  const {
    evaluateEvaluationSetReset,
    evaluateEvaluationSetLoading,
    evaluateEvaluationSetErrorMessage,
    evaluateEvaluationSet
  } = useEvaluateEvaluationSet<EvaluateEvaluationSetQueryResponse>({
    query: EVALUATE_EVALUATION_SET_QUERY,
    cacheKeys: [EvaluationSetCache.dashboardCacheKey()]
  });

  const watchTotalRate = watch(EvaluateEvaluationSetFields.TOTAL_RATE);

  return {
    control,
    evaluateEvaluationSetErrorMessage,
    evaluateEvaluationSetLoading,
    resetEvaluateEvaluationSetClientForm: resetForm,
    evaluateEvaluationSetReset,
    evaluationSetIndex,
    isDetailedView: watchTotalRate > 0 && watchTotalRate < MAX_EVALUATION_RATE,
    handleHideModal,
    handlePostpone: useCallback(
      () =>
        LocalForage.getItemWithExpiry<EvaluationSetUUID[]>(
          POSTPONED_EVALUATION_SET_UUIDS
        ).then((postponedUuids) =>
          LocalForage.setItemWithExpiry<EvaluationSetUUID[]>(
            POSTPONED_EVALUATION_SET_UUIDS,
            isArray(postponedUuids)
              ? [...postponedUuids, evaluationSets[evaluationSetIndex].uuid]
              : [evaluationSets[evaluationSetIndex].uuid],
            DEFER_EVALUATION_TIME
          ).then(() => handleHideModal())
        ),
      [evaluationSets, evaluationSetIndex, handleHideModal]
    ),
    handleEvaluate: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: async (data) => {
        const evaluationData =
          data.totalRate < MAX_EVALUATION_RATE
            ? omit(data, EvaluateEvaluationSetFields.TOTAL_RATE)
            : MAX_EVALUATION_RATES;

        return evaluateEvaluationSet({
          ...evaluationData,
          ...defaultWorkerEvaluationValues,
          uuid: evaluationSets[evaluationSetIndex].uuid
        }).then(() => {
          resetForm();
          if (evaluationSetIndex >= evaluationSetsSize - 1) {
            hideModal();
          }
        });
      }
    }),
    isOpen
  };
}

export default useEvaluateEvaluationSetClientForm;
