import { useState } from 'react';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import {
  FETCH_EVALUATION_SETS_QUERY,
  FetchEvaluationSetsQueryResponse
} from '../../../queries/fetchEvaluationSets.query';

import { EvaluateEvaluationSets } from '../../forms/EvaluateEvaluationSetClientForm/EvaluateEvaluationSetClientForm.types';
import { EvaluationSetUUID } from '../../../evaluationSetsTypes';

import { usePaginatedEvaluationSets } from '../../../hooks/usePaginatedEvaluationSets';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { EvaluateEvaluationSetModal } from '../../modals/EvaluateEvaluationSetModal';

import { Loading } from '../../../../../helpers/Loading';
import { LocalForage } from '../../../../../utils/LocalForage';

import { EvaluationSetCache } from '../../../EvaluationSetCache';

import { EVALUATE_EVALUATION_SET_CLIENT_FORM } from '../../forms/EvaluateEvaluationSetClientForm/evaluateEvaluationSetClientFormConstants';

import {
  EvaluationSetsPermissions,
  POSTPONED_EVALUATION_SET_UUIDS
} from '../../../evaluationSetsConstants';

function EvaluateEvaluationSetsContent() {
  const currentUser = useCurrentUser();

  const [actualEvaluationSets, setActualEvaluationSets] =
    useState<EvaluateEvaluationSets>([]);

  const { evaluationSetsFetched } =
    usePaginatedEvaluationSets<FetchEvaluationSetsQueryResponse>({
      cacheKey: EvaluationSetCache.dashboardCacheKey(),
      query: FETCH_EVALUATION_SETS_QUERY,
      initialLimit: 10,
      initialPage: 1,
      initialFilters: {
        state: { eq: 'waiting' },
        own: true
      },
      options: {
        onSuccess: (data) => {
          const values = data?.evaluationSets?.nodes;

          if (isEmpty(values)) {
            return;
          }

          LocalForage.getItemWithExpiry<EvaluationSetUUID[]>(
            POSTPONED_EVALUATION_SET_UUIDS
          ).then((postponedUuids) =>
            setActualEvaluationSets(
              filter(values, ({ uuid }) => !includes(postponedUuids, uuid))
            )
          );
        }
      }
    });

  return (
    <Loading loaded={evaluationSetsFetched} withoutLoader>
      {isEmpty(actualEvaluationSets) ? null : (
        <EvaluateEvaluationSetModal
          evaluationSets={actualEvaluationSets}
          form={EVALUATE_EVALUATION_SET_CLIENT_FORM}
          withPostponeButton={currentUser.hasPermissions(
            EvaluationSetsPermissions.READ_DEFER_EVALUATION_BUTTON
          )}
        />
      )}
    </Loading>
  );
}

export default EvaluateEvaluationSetsContent;
