import { EvaluateEvaluationSetClientFormData } from './EvaluateEvaluationSetClientForm.types';

export const DEFER_EVALUATION_TIME = 1000 * 60 * 60 * 24;

export const MAX_EVALUATION_RATE = 6;

export const MAX_EVALUATION_RATES = {
  accuracy: MAX_EVALUATION_RATE,
  communication: MAX_EVALUATION_RATE,
  quality: MAX_EVALUATION_RATE,
  timing: MAX_EVALUATION_RATE
};

export const defaultWorkerEvaluationValues = {
  taskError: 0,
  projectError: 0,
  profileError: 0,
  briefError: 0,
  estimateError: 0,
  styleError: 0,
  burningTasks: 0,
  respondRate: 0,
  estimateRate: 0,
  qualityAssurance: 0,
  textureLightingCamera: 0,
  compositionDetailsStorytelling: 0
};

export const defaultClientEvaluationValues: EvaluateEvaluationSetClientFormData =
  {
    accuracy: 0,
    communication: 0,
    quality: 0,
    timing: 0,
    totalRate: 0
  };

export const EVALUATE_EVALUATION_SET_CLIENT_FORM =
  'evaluate-evaluation-set-client-form';
