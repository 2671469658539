import { Control, Controller, FieldPath } from 'react-hook-form';

import { ClassName } from '../../../types';

import {
  StarsRadioButtons,
  StarsRadioButtonsAmount
} from '../../StarsRadioButtons';

interface StarsRadioButtonsFieldProps<T> {
  control: Control<T>;
  name: FieldPath<T>;
  amount?: StarsRadioButtonsAmount;
  iconClassName?: ClassName;
  disabled?: boolean;
}

function StarsRadioButtonsField<T>({
  control,
  name,
  amount,
  disabled,
  iconClassName
}: StarsRadioButtonsFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <StarsRadioButtons
          name={name}
          value={value as number | undefined}
          amount={amount}
          disabled={disabled}
          iconClassName={iconClassName}
          onChange={onChange}
          error={error?.message}
        />
      )}
    />
  );
}

export default StarsRadioButtonsField;
