import { FetchItemCacheKey, FetchItemsGqlQuery } from '../../../../types';

import {
  FetchEvaluationSetsGqlQuery,
  FetchEvaluationSetsCacheKey,
  FetchEvaluationSetsFilters,
  FetchEvaluationSetsSort,
  FetchEvaluationSetsPage,
  FetchEvaluationSetsLimit
} from '../../evaluationSetsTypes';

import {
  IndexQueryResponse,
  useIndexQuery
} from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_EVALUATION_SETS_FILTERS,
  INITIAL_EVALUATION_SETS_LIMIT,
  INITIAL_EVALUATION_SETS_PAGE,
  INITIAL_EVALUATION_SETS_SORT
} from '../../evaluationSetsConstants';

interface EvaluationSetsDefaultOptions<ItemType> {
  cacheKey: FetchEvaluationSetsCacheKey;
  query: FetchEvaluationSetsGqlQuery;
  initialFilters?: FetchEvaluationSetsFilters;
  initialSort?: FetchEvaluationSetsSort;
  initialPage?: FetchEvaluationSetsPage;
  initialLimit?: FetchEvaluationSetsLimit;
  options?: {
    cacheTime?: number;
    onSuccess?: (data: IndexQueryResponse<ItemType>) => void;
  };
}

interface EvaluationSetsWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface EvaluationSetsWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

type EvaluationSetsOptions<ItemType> = EvaluationSetsDefaultOptions<ItemType> &
  (
    | EvaluationSetsWithPrefetchItemOptions
    | EvaluationSetsWithoutPrefetchItemOptions
  );

const scope = 'evaluationSets';

function usePaginatedEvaluationSets<ItemType>({
  cacheKey,
  query,
  initialFilters = INITIAL_EVALUATION_SETS_FILTERS,
  initialSort = INITIAL_EVALUATION_SETS_SORT,
  initialPage = INITIAL_EVALUATION_SETS_PAGE,
  initialLimit = INITIAL_EVALUATION_SETS_LIMIT,
  fetchItemCacheKey,
  fetchItemQuery,
  options = {}
}: EvaluationSetsOptions<ItemType>) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    clearItemsFilters,
    filterItems,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems,
    prefetchItem
  } = useIndexQuery<ItemType>({
    scope,
    query,
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    fetchItemCacheKey,
    fetchItemQuery,
    options
  });

  return {
    evaluationSetsData: data,
    evaluationSets: items,
    evaluationSetsError: itemsError,
    evaluationSetsTotalCount: itemsTotalCount,
    evaluationSetsFetched: isFetched,
    evaluationSetsLoading: isLoading,
    evaluationSetsIsPlaceholderData: isPlaceholderData,
    evaluationSetsFilters: currentFilters,
    evaluationSetsSort: currentSort,
    evaluationSetsPage: currentPage,
    evaluationSetsLimit: currentLimit,
    filterEvaluationSets: filterItems,
    clearEvaluationSetsFilters: clearItemsFilters,
    sortEvaluationSets: sortItems,
    paginateEvaluationSets: paginateItems,
    limitEvaluationSets: limitItems,
    prefetchEvaluationSets: prefetchItems,
    prefetchEvaluationSet: prefetchItem
  };
}

export default usePaginatedEvaluationSets;
