import size from 'lodash/size';

import { EvaluateEvaluationSets } from '../../forms/EvaluateEvaluationSetClientForm/EvaluateEvaluationSetClientForm.types';

import { useEvaluateEvaluationSetClientForm } from '../../forms/EvaluateEvaluationSetClientForm/hooks/useEvaluateEvaluationSetClientForm';

import { EvaluateEvaluationSetClientForm } from '../../forms/EvaluateEvaluationSetClientForm';
import { EvaluateEvaluationSetProjectContent } from '../../content/EvaluateEvaluationSetProjectContent';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModal } from '../../../../../helpers/modals/FormModal';

import {
  evaluationsKeys,
  projectsKeys,
  words
} from '../../../../../locales/keys';

interface EvaluateEvaluationSetModalProps {
  form: string;
  evaluationSets: EvaluateEvaluationSets;
  withPostponeButton?: boolean;
}

function EvaluateEvaluationSetModal({
  evaluationSets,
  form,
  withPostponeButton = false
}: EvaluateEvaluationSetModalProps) {
  const {
    control,
    evaluateEvaluationSetErrorMessage,
    evaluateEvaluationSetLoading,
    handleEvaluate,
    isDetailedView,
    isOpen,
    evaluationSetIndex,
    handleHideModal,
    handlePostpone
  } = useEvaluateEvaluationSetClientForm({
    evaluationSets
  });

  if (evaluationSetIndex > size(evaluationSets) - 1) {
    return null;
  }

  return (
    <FormModal
      submitDisabled={evaluateEvaluationSetLoading}
      form={form}
      handleSubmit={handleEvaluate}
      hideModal={handleHideModal}
      handleCancel={handlePostpone}
      i18nSubmitText={words.submit}
      i18nCancelText={evaluationsKeys.postponeUntilTomorrow}
      i18nTitle={projectsKeys.pleaseEvaluate}
      isLoading={evaluateEvaluationSetLoading}
      isOpen={isOpen}
      withoutCancel={!withPostponeButton}
    >
      <div className="flex-1 overflow-y-auto px-2 z-0">
        <div className="p-4">
          <div className="space-y-4">
            <EvaluateEvaluationSetProjectContent
              project={evaluationSets[evaluationSetIndex].project}
            />
            <EvaluateEvaluationSetClientForm
              control={control}
              form={form}
              isDetailedView={isDetailedView}
              isLoading={evaluateEvaluationSetLoading}
            />
          </div>
        </div>
      </div>
      <div className="px-4">
        <AlertMessage message={evaluateEvaluationSetErrorMessage} />
      </div>
    </FormModal>
  );
}

export default EvaluateEvaluationSetModal;
