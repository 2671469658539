import { gql } from 'graphql-request';

import { EvaluationSetID, EvaluationSetUUID } from '../evaluationSetsTypes';

export interface EvaluateEvaluationSetQueryResponse {
  id: EvaluationSetID;
  uuid: EvaluationSetUUID;
}

export const EVALUATE_EVALUATION_SET_QUERY = gql`
  mutation EvaluateEvaluationSet(
    $uuid: ID!
    $accuracy: Integer!
    $communication: Integer!
    $timing: Integer!
    $quality: Integer!
    $taskError: Integer!
    $projectError: Integer!
    $profileError: Integer!
    $briefError: Integer!
    $estimateError: Integer!
    $styleError: Integer!
    $burningTasks: Integer!
    $respondRate: Integer!
    $estimateRate: Integer!
    $qualityAssurance: Integer!
    $textureLightingCamera: Integer!
    $compositionDetailsStorytelling: Integer!
  ) {
    evaluateEvaluationSet(
      input: {
        uuid: $uuid
        accuracy: $accuracy
        communication: $communication
        timing: $timing
        quality: $quality
        taskError: $taskError
        projectError: $projectError
        profileError: $profileError
        briefError: $briefError
        estimateError: $estimateError
        styleError: $styleError
        burningTasks: $burningTasks
        respondRate: $respondRate
        estimateRate: $estimateRate
        qualityAssurance: $qualityAssurance
        textureLightingCamera: $textureLightingCamera
        compositionDetailsStorytelling: $compositionDetailsStorytelling
      }
    ) {
      status
      recordUuid
      record {
        id
        uuid
        state
        createdAt
        updatedAt
      }
      errors {
        fullMessages
      }
    }
  }
`;
