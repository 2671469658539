import { EvaluateEvaluationSetProject } from '../../forms/EvaluateEvaluationSetClientForm/EvaluateEvaluationSetClientForm.types';

import { MoneyHelper } from '../../../../../helpers/MoneyHelper';
import { NextPureLinkHelper } from '../../../../../helpers/links/NextPureLinkHelper';
import { Translate } from '../../../../../helpers/Translate';
import { UserAvatar } from '../../../../common/helpers/UserAvatar';

import { ProjectPath } from '../../../../projects/ProjectPath';

import { projectsKeys, resultsKeys } from '../../../../../locales/keys';

interface EvaluateEvaluationSetProjectContentProps {
  project: EvaluateEvaluationSetProject;
}

function EvaluateEvaluationSetProjectContent({
  project
}: EvaluateEvaluationSetProjectContentProps) {
  return (
    <>
      <div className="flex flex-col">
        <p className="text-sm font-medium">
          <Translate id={projectsKeys.name} />
        </p>
        <NextPureLinkHelper
          className="text-sm sm:text-base font-bold focus:outline-none dark:text-gray-200 text-gray-900 hover:text-gray-600 dark:hover:text-gray-50"
          href={ProjectPath.show(project.nanoId)}
          text={project.name}
        />
        <NextPureLinkHelper
          className="focus:outline-none hover:underline text-sm"
          href={ProjectPath.results(project.nanoId)}
          i18nText={resultsKeys.see}
        />
      </div>

      <div className="space-y-2">
        <p className="text-sm font-medium">
          <Translate id={projectsKeys.team} />
        </p>
        <div className="flex gap-1 flex-wrap">
          {project.members?.map((user) => (
            <div className="flex items-center space-x-4" key={user.uuid}>
              <UserAvatar type="main" user={user} />
            </div>
          ))}
        </div>
      </div>

      <div>
        <p className="text-sm font-medium">
          <Translate id={projectsKeys.totalCost} />
        </p>
        <p className="font-bold">
          <MoneyHelper value={project.estimateCost} />
        </p>
      </div>
    </>
  );
}

export default EvaluateEvaluationSetProjectContent;
