import { SearchFilterNanoID } from '../../../searchFilters/searchFiltersTypes';

import { ProjectsIndexPage } from '../../../projects/pages/ProjectsIndexPage';
import { EvaluateEvaluationSetsContent } from '../../../evaluationSets/components/content/EvaluateEvaluationSetsContent';

import { CheckPermissions } from '../../../../helpers/CheckPermissions';

import { ProjectCache } from '../../../projects/ProjectCache';

import { PAGE_ITEMS_LIMIT } from '../../../../common/constants';
import { DashboardPermissions } from '../../dashboardConstants';

const initialPage = 1;

const initialLimit = PAGE_ITEMS_LIMIT;

interface DashboardPageProps {
  onCreateSearchFilter?: (searchFilterNanoId: SearchFilterNanoID) => void;
}

function DashboardPage({ onCreateSearchFilter }: DashboardPageProps) {
  return (
    <>
      <ProjectsIndexPage
        cacheKey={ProjectCache.indexCacheKey()}
        initialLimit={initialLimit}
        initialPage={initialPage}
        onCreateSearchFilter={onCreateSearchFilter}
      />
      <CheckPermissions
        action={DashboardPermissions.READ_DASHBOARD_EVALUATE_PROJECTS_BLOCK}
      >
        <EvaluateEvaluationSetsContent />
      </CheckPermissions>
    </>
  );
}

export default DashboardPage;
