import * as yup from 'yup';

import { EvaluateEvaluationSetFields } from '../../../../../evaluationSetsTypes';

import { formsErrors } from '../../../../../../../locales/keys';

import { MAX_EVALUATION_RATE } from '../../evaluateEvaluationSetClientFormConstants';

export const evaluateEvaluationSetClientFormSchema = yup.object({
  [EvaluateEvaluationSetFields.TOTAL_RATE]: yup
    .number()
    .min(1, formsErrors.required)
    .required(formsErrors.required),
  [EvaluateEvaluationSetFields.ACCURACY]: yup
    .number()
    .when([EvaluateEvaluationSetFields.TOTAL_RATE], {
      is: (value: number) => value < MAX_EVALUATION_RATE,
      then: yup
        .number()
        .min(1, formsErrors.required)
        .required(formsErrors.required)
    }),
  [EvaluateEvaluationSetFields.COMMUNICATION]: yup
    .number()
    .when([EvaluateEvaluationSetFields.TOTAL_RATE], {
      is: (value: number) => value < MAX_EVALUATION_RATE,
      then: yup
        .number()
        .min(1, formsErrors.required)
        .required(formsErrors.required)
    }),
  [EvaluateEvaluationSetFields.QUALITY]: yup
    .number()
    .when([EvaluateEvaluationSetFields.TOTAL_RATE], {
      is: (value: number) => value < MAX_EVALUATION_RATE,
      then: yup
        .number()
        .min(1, formsErrors.required)
        .required(formsErrors.required)
    }),
  [EvaluateEvaluationSetFields.TIMING]: yup
    .number()
    .when([EvaluateEvaluationSetFields.TOTAL_RATE], {
      is: (value: number) => value < MAX_EVALUATION_RATE,
      then: yup
        .number()
        .min(1, formsErrors.required)
        .required(formsErrors.required)
    })
});
