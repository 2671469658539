import {
  FetchItemsCacheKey,
  FetchItemsClearItemsFilters,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount
} from '../../../types';

import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../../common/hooks/useTableCheckable';

import {
  EvaluationSetCreatedAtFilter,
  EvaluationSetNameFilter,
  EvaluationSetOwnFilter,
  EvaluationSetProjectNameFilter,
  EvaluationSetProjectUuidFilter,
  EvaluationSetStateFilter
} from './evaluationSetsFields';

export interface FetchEvaluationSetsFilters {
  createdAt?: EvaluationSetCreatedAtFilter;
  name?: EvaluationSetNameFilter;
  projectUuid?: EvaluationSetProjectUuidFilter;
  projectName?: EvaluationSetProjectNameFilter;
  state?: EvaluationSetStateFilter;
  own?: EvaluationSetOwnFilter;
}

export const enum FetchEvaluationSetsSortableFields {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt'
}

export const enum FetchEvaluationSetsFilterFields {
  NAME = 'name',
  CREATED_AT = 'createdAt',
  PROJECT_NAME = 'projectName',
  STATE = 'state.in'
}

export const enum FetchEvaluationSetsSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC'
}

export type FetchEvaluationSetsGqlQuery = FetchItemsGqlQuery;

export type FetchEvaluationSetsCacheKey = FetchItemsCacheKey;

export type FetchEvaluationSetsFetched = FetchItemsFetched;
export type FetchEvaluationSetsErrorMessage = FetchItemsErrorMessage;
export type FetchEvaluationSetsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchEvaluationSetsPage = FetchItemsPage;
export type FetchEvaluationSetsLimit = FetchItemsLimit;
export type FetchEvaluationSetsSort = FetchItemsSort; // FetchEvaluationSetsSortTypes[];
export type FetchEvaluationSetsTotalCount = FetchItemsTotalCount;
export type FetchEvaluationSetsFilterEvaluationSets =
  FetchItemsFilterItems<FetchEvaluationSetsFilters>;
export type FetchEvaluationSetsClearEvaluationSetsFilters =
  FetchItemsClearItemsFilters;
export type FetchEvaluationSetsSortEvaluationSets = FetchItemsSortItems;
export type FetchEvaluationSetsPaginateEvaluationSets = FetchItemsPaginateItems;
export type FetchEvaluationSetsPrefetchEvaluationSets = FetchItemsPrefetchItems;

export type FetchEvaluationSetsShowEvaluationSet = FetchItemsShowItem;
export type FetchEvaluationSetsEditEvaluationSet = FetchItemsEditItem;

export type FetchEvaluationSetsChecked = Checked;
export type FetchEvaluationSetsCheckedHash = CheckedHashItem;
export type FetchEvaluationSetsOnSetCheckedIds = OnSetCheckedIds;
export type FetchEvaluationSetsCheckedAll = CheckedAll;
export type FetchEvaluationSetsOnCheckAll = OnCheckAll;
