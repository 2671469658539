import { FetchEvaluationSetsSortTypes } from './evaluationSetsTypes';

import { evaluationSetsKeys } from '../../locales/keys';

export const INITIAL_EVALUATION_SETS_FILTERS = {};
export const INITIAL_EVALUATION_SETS_PAGE = 1;
export const INITIAL_EVALUATION_SETS_SORT = [
  FetchEvaluationSetsSortTypes.CREATED_AT_DESC
];
export const INITIAL_EVALUATION_SETS_LIMIT = 50;

export const enum EvaluationSetsPermissions {
  READ_DEFER_EVALUATION_BUTTON = 'read_defer_evaluation_button',
  READ_EVALUATION_SETS_INDEX_PAGE = 'read_evaluation_sets_index_page'
}

export const POSTPONED_EVALUATION_SET_UUIDS = 'postponed-evaluation-set-uuids';

export const evaluationSetStates = [
  { value: 'waiting', i18nLabel: evaluationSetsKeys.states.waiting },
  { value: 'done', i18nLabel: evaluationSetsKeys.states.done },
  { value: 'canceled', i18nLabel: evaluationSetsKeys.states.canceled }
];
