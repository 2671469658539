import {
  EvaluationSetUUID,
  EvaluateEvaluationSetGqlQuery,
  EvaluateEvaluationSetGqlStatus,
  EvaluateEvaluationSetGqlError,
  EvaluationSetAccuracy,
  EvaluationSetCommunication,
  EvaluationSetTiming,
  EvaluationSetQuality,
  EvaluationSetTaskError,
  EvaluationSetProfileError,
  EvaluationSetBriefError,
  EvaluationSetEstimateError,
  EvaluationSetStyleError,
  EvaluationSetBurningTasks,
  EvaluationSetRespondRate,
  EvaluationSetEstimateRate,
  EvaluationSetQualityAssurance,
  EvaluationSetTextureLightingCamera,
  EvaluationSetCompositionDetailsStorytelling,
  EvaluateEvaluationSetCacheKeys
} from '../../evaluationSetsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface EvaluateEvaluationSetInput {
  uuid: EvaluationSetUUID;
  accuracy: EvaluationSetAccuracy;
  communication: EvaluationSetCommunication;
  timing: EvaluationSetTiming;
  quality: EvaluationSetQuality;
  taskError: EvaluationSetTaskError;
  projectError: EvaluationSetTaskError;
  profileError: EvaluationSetProfileError;
  briefError: EvaluationSetBriefError;
  estimateError: EvaluationSetEstimateError;
  styleError: EvaluationSetStyleError;
  burningTasks: EvaluationSetBurningTasks;
  respondRate: EvaluationSetRespondRate;
  estimateRate: EvaluationSetEstimateRate;
  qualityAssurance: EvaluationSetQualityAssurance;
  textureLightingCamera: EvaluationSetTextureLightingCamera;
  compositionDetailsStorytelling: EvaluationSetCompositionDetailsStorytelling;
}

interface EvaluateEvaluationSetResponse<EvaluateEvaluationSetRecordType> {
  evaluateEvaluationSet: {
    status: EvaluateEvaluationSetGqlStatus;
    recordUuid: EvaluationSetUUID;
    record: EvaluateEvaluationSetRecordType;
    errors: EvaluateEvaluationSetErrors;
  };
}

interface EvaluateEvaluationSetErrors {
  name: EvaluateEvaluationSetGqlError;
  fullMessages: EvaluateEvaluationSetGqlError;
}

interface EvaluateEvaluationSetOptions {
  query: EvaluateEvaluationSetGqlQuery;
  cacheKeys?: EvaluateEvaluationSetCacheKeys;
}

const action = 'evaluateEvaluationSet';

function useEvaluateEvaluationSet<EvaluateEvaluationSetRecordType>({
  query,
  cacheKeys
}: EvaluateEvaluationSetOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    EvaluateEvaluationSetInput,
    EvaluateEvaluationSetResponse<EvaluateEvaluationSetRecordType>,
    EvaluateEvaluationSetErrors,
    EvaluateEvaluationSetRecordType
  >({ action, cacheKeys, query });

  return {
    evaluateEvaluationSetData: updateQueryData,
    evaluateEvaluationSetError: updateQueryError,
    evaluateEvaluationSetLoading: updateQueryLoading,
    evaluateEvaluationSetErrorMessage: updateQueryErrorMessage,
    evaluateEvaluationSet: updateQuery,
    evaluateEvaluationSetReset: updateQueryReset
  };
}

export default useEvaluateEvaluationSet;
