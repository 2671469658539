import { useCallback } from 'react';
import cl from 'classnames';

import { ClassName } from '../../../../types';
import { IconsEnum } from '../../../../assets/icons/types';

import {
  StarsRadioButtonsValue,
  StarsRadioButtonsOnChange
} from '../../StarsRadioButtons.types';

import { Icon } from '../../../Icon';

export interface StarsRadioButtonsItemProps {
  name: string;
  value?: StarsRadioButtonsValue;
  buttonValue: StarsRadioButtonsValue;
  disabled?: boolean;
  iconClassName?: ClassName;
  onChange: StarsRadioButtonsOnChange;
}

function StarsRadioButtonsItem({
  name,
  value,
  buttonValue,
  disabled,
  iconClassName,
  onChange
}: StarsRadioButtonsItemProps) {
  const handleChange = useCallback<() => void>(() => {
    onChange(buttonValue);
  }, [buttonValue, onChange]);

  const id = `star-radio-button-${name}-${buttonValue}`;

  return (
    <>
      <input
        id={id}
        type="radio"
        name={name}
        value={buttonValue}
        checked={value === buttonValue}
        className="sr-only"
        disabled={disabled}
        onChange={handleChange}
      />
      <label htmlFor={id} className={cl({ hidden: buttonValue === 0 })}>
        <Icon
          icon={IconsEnum.FILLED_STAR}
          className={iconClassName || 'h-6 w-6'}
        />
      </label>
    </>
  );
}

export default StarsRadioButtonsItem;
