import { gql } from 'graphql-request';

import {
  EvaluationSetCreatedAt,
  EvaluationSetEvaluationCommunication,
  EvaluationSetEvaluationQuality,
  EvaluationSetEvaluationsAccuracy,
  EvaluationSetEvaluationsUserClient,
  EvaluationSetEvaluationsUserFinanceRoleName,
  EvaluationSetEvaluationsUserFullName,
  EvaluationSetEvaluationsUserImageFile,
  EvaluationSetEvaluationsUserImageUUID,
  EvaluationSetEvaluationsUserNanoID,
  EvaluationSetEvaluationsUUID,
  EvaluationSetEvaluationTiming,
  EvaluationSetID,
  EvaluationSetProjectCreatedAt,
  EvaluationSetProjectDescription,
  EvaluationSetProjectEstimateCost,
  EvaluationSetProjectID,
  EvaluationSetProjectMembersClient,
  EvaluationSetProjectMembersFinanceRoleName,
  EvaluationSetProjectMembersFullName,
  EvaluationSetProjectMembersImageFile,
  EvaluationSetProjectMembersImageUUID,
  EvaluationSetProjectMembersNanoID,
  EvaluationSetProjectMembersUUID,
  EvaluationSetProjectName,
  EvaluationSetProjectNanoID,
  EvaluationSetProjectUserClient,
  EvaluationSetProjectUserFinanceRoleName,
  EvaluationSetProjectUserFullName,
  EvaluationSetProjectUserImageFile,
  EvaluationSetProjectUserImageUUID,
  EvaluationSetProjectUserNanoID,
  EvaluationSetProjectUserUUID,
  EvaluationSetProjectUUID,
  EvaluationSetState,
  EvaluationSetUpdatedAt,
  EvaluationSetUUID
} from '../evaluationSetsTypes';

export interface FetchEvaluationSetsQueryResponse {
  id: EvaluationSetID;
  uuid: EvaluationSetUUID;
  createdAt: EvaluationSetCreatedAt;
  updatedAt: EvaluationSetUpdatedAt;
  state: EvaluationSetState;
  projectId: EvaluationSetProjectID;
  project: {
    id: EvaluationSetProjectID;
    uuid: EvaluationSetProjectUUID;
    nanoId: EvaluationSetProjectNanoID;
    name: EvaluationSetProjectName;
    description: EvaluationSetProjectDescription;
    estimateCost: EvaluationSetProjectEstimateCost;
    createdAt: EvaluationSetProjectCreatedAt;
    user: {
      uuid: EvaluationSetProjectUserUUID;
      nanoId: EvaluationSetProjectUserNanoID;
      client: EvaluationSetProjectUserClient;
      fullName: EvaluationSetProjectUserFullName;
      financeRoleName: EvaluationSetProjectUserFinanceRoleName;
      image: {
        uuid: EvaluationSetProjectUserImageUUID;
        file: EvaluationSetProjectUserImageFile;
      };
    };
    members: {
      uuid: EvaluationSetProjectMembersUUID;
      nanoId: EvaluationSetProjectMembersNanoID;
      client: EvaluationSetProjectMembersClient;
      fullName: EvaluationSetProjectMembersFullName;
      financeRoleName: EvaluationSetProjectMembersFinanceRoleName;
      image: {
        uuid: EvaluationSetProjectMembersImageUUID;
        file: EvaluationSetProjectMembersImageFile;
      };
    }[];
  };
  evaluations: {
    uuid: EvaluationSetEvaluationsUUID;
    accuracy: EvaluationSetEvaluationsAccuracy;
    communication: EvaluationSetEvaluationCommunication;
    timing: EvaluationSetEvaluationTiming;
    quality: EvaluationSetEvaluationQuality;
    user: {
      nanoId: EvaluationSetEvaluationsUserNanoID;
      fullName: EvaluationSetEvaluationsUserFullName;
      client: EvaluationSetEvaluationsUserClient;
      financeRoleName: EvaluationSetEvaluationsUserFinanceRoleName;
      image: {
        uuid: EvaluationSetEvaluationsUserImageUUID;
        file: EvaluationSetEvaluationsUserImageFile;
      };
    };
  }[];
}

export const FETCH_EVALUATION_SETS_QUERY = gql`
  query EvaluationSets(
    $filters: EvaluationSetsFilters
    $sort: [EvaluationSetsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    evaluationSets(
      filters: $filters
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      nodes {
        id
        uuid
        createdAt
        updatedAt
        state
        projectId
        project {
          id
          uuid
          nanoId
          name
          description
          estimateCost
          createdAt
          user {
            uuid
            nanoId
            fullName
            image {
              uuid
              file
            }
          }
          members {
            uuid
            nanoId
            fullName
            image {
              uuid
              file
            }
          }
        }
        evaluations {
          uuid
          accuracy
          communication
          timing
          quality
          user {
            nanoId
            fullName
            client
            financeRoleName
            image {
              uuid
              file
            }
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
